import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Layout from "../components/Layout/layout";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import SEO from "../components/seo";

const Blogs = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicBlogPage(sort: { fields: data___blog_date, order: DESC }) {
        edges {
          node {
            data {
              author_name
              blog_date(formatString: "Do MMMM, YYYY")
              slug
              type
              blog_title {
                text
              }
              cover_image {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);
  return (
    <>
    <SEO 
    title = "Blog - Kabira Mobility"/>
      <Layout>
        <div className="mx-6 mt-10 md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg md:mx-auto">
          <h1 className="text-3xl md:text-4xl text-gray-700 font-semibold md:mb-8 md:mt-8">Latest News</h1>
          <div className="flex flex-col space-y-6 md:space-y-0 md:grid md:grid-cols-2 md:gap-4">
          {data.allPrismicBlogPage.edges.slice(0, 4).map((item) => {
            return (
              <>
                <Link
                to = {"/news/" + item.node.data.slug}>
                  <div className="relative mt-6 flex flex-col space-y-5">
                    <GatsbyImage
                      image={getImage(item.node.data.cover_image)}
                      className="bg-cover h-[500px] w-full rounded-xl"
                    />
                    <div className="flex flex-col space-y-2">
                      <h5 className="uppercase text-base font-bold text-gray-500">
                      {item.node.data.type}
                      </h5>
                      <h1 className="capitalize font-semibold text-gray-700 text-2xl md:text-3xl md:leading-relaxed">
                        {item.node.data.blog_title.text}
                      </h1>
                      <p className="text-sm md:text-lg text-gray-400 font-regular">
                      {item.node.data.blog_date}
                      </p>
                    </div>
                  </div>
                </Link>
              </>
            );
          })}
            </div>
          <div className="mt-8 md:mt-20">
            <h2 className="text-3xl md:text-4xl text-gray-700 font-semibold mb-4">
              All Stories
            </h2>
            <div className="flex flex-col divide-y md:grid md:grid-cols-2 md:gap-x-4">
            {data.allPrismicBlogPage.edges.slice(4).map((item) => {
              return (<>
              <Link
                to = {"/news/" + item.node.data.slug}>
                <div className="grid grid-cols-3 gap-4 py-4 md:py-6">
                <GatsbyImage
                      image={getImage(item.node.data.cover_image)}
                      className="bg-cover h-full aspect-w-1 aspect-h-1 rounded col-span-1"
                    />
                    <div className="col-span-2 flex flex-col space-y  justify-center">
                    <h5 className="uppercase text-sm font-semibold text-gray-500">
                    {item.node.data.type}
                    </h5>
                    <h3 className="capitalize font-semibold text-gray-700 text-base md:text-lg md:leading-normal leading-normal ">
                    {item.node.data.blog_title.text}
                    </h3>
                    <p className="text-xs text-gray-400 font-regular mt-2">
                    {item.node.data.blog_date}
                    </p>
                  </div>
              </div>
              </ Link>
              </>)
            })}
              
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Blogs;
